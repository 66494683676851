import { createSelector, createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { TFunction } from "i18next";
import {
  Direction,
  IDormer,
  IFloor,
  IInspection,
  IRoof,
  ISolarPanel,
  IWall,
  IWindow,
  IWindowGroup,
  Loading,
  SuggestionCategory,
  SummaryAgreement,
  SummaryData,
  SummaryElements,
  typedEntries,
} from "../../types";
import { openHouseDatabase, updateIndexedDB } from "../indexedDB/objectStore";
import { RootState } from "../store";
import { fetchInspection } from "../thunks";
import { SummarySchemaType } from "src/pages/Summary/SummaryCard";
import { getTrimmedName } from "src/utils/helpers/getTrimmedName";
import { t } from "i18next";

const initialState: { data: IInspection; loading: string; error: string } = {
  data: {} as IInspection,
  loading: "",
  error: "",
};

export const inspectionSlice = createSlice({
  name: "inspection",
  initialState,
  reducers: {
    saveRoof: (state, action) => {
      const { ...newRoof } = action.payload;
      if (action.payload?.measure?.insulationProduct?.name === "geen") {
        action.payload.measure.insulationProduct = null;
        action.payload.measure.insulationThickness = 0;
      }
      state.data.roofs.push(newRoof);
      updateIndexedDB(current(state.data));
    },
    deleteRoof: (state, action) => {
      state.data.roofs = state.data.roofs.filter(
        // Trim trailing blank spaces from Roof names to avoid mismatches,
        // as some names in the database contain extra spaces. This helps ensure accurate
        // matching and prevents issues caused by names with trailing whitespace.
        // Ticket #471
        (roof) => getTrimmedName(roof.name) !== action.payload.roofId,
      );
      updateIndexedDB(current(state.data));
    },
    saveSolarPanel: (state, action) => {
      const { solarId } = action.payload;
      const solarPanel = state.data.solar?.measures.findIndex((solar) => solar.name === solarId);
      delete action.payload.solarId;
      let solarProduct = null;
      if (action.payload.panelType !== "geen") {
        solarProduct = {
          name: action.payload.panelType,
          id: action.payload.panelTypeId,
        };
      }
      /* remove panelType prop */
      delete action.payload.panelType;
      delete action.payload.panelTypeId;
      if (solarPanel > -1) {
        state.data.solar.measures[solarPanel] = { ...action.payload, solarProduct };
      } else {
        state.data.solar.measures.push({ ...action.payload, solarProduct });
      }
      updateIndexedDB(current(state.data));
    },
    saveSolarPage: (state, action) => {
      state.data.notes = {
        ...state.data.notes,
        ownerSolar: action.payload.ownerSolar,
        supplierSolar: action.payload.supplierSolar,
      };
      state.data.solar = {
        ...state.data.solar,
        images: action.payload.images,
        currentPhaseConnection: action.payload.currentPhaseConnection,
        requiredPhaseConnection: action.payload.requiredPhaseConnection,
        mainFuseBoxNeedsReplacement: action.payload.mainFuseBoxNeedsReplacement,
        mainFuseBoxLocation: action.payload.mainFuseBoxLocation,
        hasFuseBoxInGarage: action.payload.hasFuseBoxInGarage,
        suggestedCableRoute: action.payload.suggestedCableRoute,
        errors: action.payload.errors,
      };
      updateIndexedDB(current(state.data));
    },
    deleteSolar: (state: any, action) => {
      state.data.solar.measures = state.data.solar.measures?.filter(
        (solar: any) => solar.name !== action.payload.solarId,
      );
      updateIndexedDB(current(state.data));
    },
    saveDormer: (state, action) => {
      const { roofId, ...newDormer } = action.payload;
      state.data.roofs.find((roof) => roof.name === newDormer.roofName)?.dormers.push(newDormer);
      updateIndexedDB(current(state.data));
    },
    saveAdditionalInspectionData: (state, action) => {
      const {
        isScaffoldingRequired,
        wallDrillDiameter,
        wallCavityBrushCount,
        ownerWall,
        supplierWall,
        ventilationGrilleCount,
        errors,
      } = action.payload;
      state.data.isScaffoldingRequired = isScaffoldingRequired;
      state.data.wallCavityBrushCount = wallCavityBrushCount;
      state.data.wallDrillDiameter = wallDrillDiameter;
      state.data.notes.ownerWall = ownerWall;
      state.data.notes.supplierWall = supplierWall;
      state.data.ventilationGrilleCount = ventilationGrilleCount;
      state.data.errors = errors;
      updateIndexedDB(current(state.data));
    },
    saveAtticFloor: (state, action) => {
      const { ...atticFloor } = action.payload;
      if (action.payload?.measure?.insulationProduct?.name === "geen") {
        action.payload.measure.insulationProduct = null;
        action.payload.measure.insulationThickness = 0;
      }

      state.data.roofs.forEach((roof, index) => {
        const roofIndex = atticFloor.roofs.findIndex(
          (item: { name: string; propportion: number }) => item.name === roof.name,
        );
        if (roofIndex > -1) {
          state.data.roofs[index].proportionAboveAtticFloor =
            +atticFloor.roofs[roofIndex].proportion / 100;
        } else {
          state.data.roofs[index].proportionAboveAtticFloor = 0;
        }
      });

      state.data.atticFloor = { ...atticFloor, roofs: undefined, numberOfRoofSections: undefined };
      updateIndexedDB(current(state.data));
    },
    deleteAtticFloor: (state) => {
      state.data.atticFloor = null;
      updateIndexedDB(current(state.data));
    },
    saveNotes: (state, action) => {
      state.data.notes = {
        ...state.data.notes,
        ...action.payload,
      };
      updateIndexedDB(current(state.data));
    },
    saveWindowNotes: (state, action) => {
      const { index, ownerWindow, supplierWindow } = action.payload;
      state.data.notes.ownerWindows[index] = ownerWindow;
      state.data.notes.supplierWindows[index] = supplierWindow;
    },
    saveWall: (state, action) => {
      const { wallId } = action.payload;
      const wall = state.data.walls.findIndex((wall) => wall.name === wallId);
      delete action.payload.wallId;
      if (action.payload?.measure?.insulationProduct?.name === "geen") {
        action.payload.measure.insulationProduct = null;
        action.payload.measure.insulationThickness = 0;
      }
      if (wall > -1) {
        state.data.walls[wall] = action.payload;
      } else {
        state.data.walls.push(action.payload);
      }
      updateIndexedDB(current(state.data));
    },
    deleteWall: (state, action) => {
      state.data.walls = state.data.walls.filter((wall) => wall.name !== action.payload.wallId);
      updateIndexedDB(current(state.data));
    },
    updateFloorArea: (state, action) => {
      const { area, index } = action.payload;
      state.data.building.storeyAreas[index] = area;

      const floor = state.data.floors.find((_floor, idx) => idx === index);
      if (floor) {
        floor.area = area;
      }
      updateIndexedDB(current(state.data));
    },
    deleteFloorArea: (state, action) => {
      const { index } = action.payload;
      if (state.data.floors[index]) {
        state.data.floors.splice(index, 1);
      }
      updateIndexedDB(current(state.data));
    },
    saveHouseInfo: (state, action) => {
      const {
        constructionYear,
        buildingType,
        ridgeHeight,
        ventilationType,
        boilerType,
        heatingTemperatureClass,
        heatRecoveryInstallationType,
      } = action.payload.building;
      const { errors, images, hoseDistance, accessibility, advisor } = action.payload;
      state.data.building.constructionYear = constructionYear;
      state.data.building.buildingType = buildingType;
      state.data.building.ridgeHeight = ridgeHeight;
      state.data.building.ventilationType = ventilationType;
      state.data.building.boilerType = boilerType;
      state.data.building.heatingTemperatureClass = heatingTemperatureClass;
      state.data.building.heatRecoveryInstallationType = heatRecoveryInstallationType;
      state.data.building.errors = errors;
      state.data.notes.internal = accessibility;
      state.data.images = images;
      state.data.hoseDistance = hoseDistance;
      state.data.notes.advisor = advisor;
      updateIndexedDB(current(state.data));
    },
    saveFloorData: (state, action) => {
      state.data.notes = {
        ...state.data.notes,
        ownerFloor: action.payload.ownerFloor,
        supplierFloor: action.payload.supplierFloor,
      };

      state.data.additionalRenovationVentCount = action.payload.additionalRenovationVentCount;
      updateIndexedDB(current(state.data));
    },
    saveFloorElement: (state, action) => {
      const { initialFloorName, ...newFloor } = action.payload;
      if (action.payload?.measure?.floorInsulationProduct?.name === "geen") {
        action.payload.measure.floorInsulationProduct = null;
        action.payload.measure.floorInsulationThickness = 0;
      }
      if (action.payload?.measure?.soilInsulationProduct?.name === "geen") {
        action.payload.measure.soilInsulationProduct = null;
        action.payload.measure.soilWallSeparationLength = 0;
        action.payload.measure.soilInsulationThickness = 0;
      }
      state.data.floors.push(newFloor);
      updateIndexedDB(current(state.data));
    },
    deleteFloorElement: (state, action) => {
      const { floorId } = action.payload;
      state.data.floors = state.data.floors.filter((floor) => floor.name !== floorId);
      updateIndexedDB(current(state.data));
    },
    editFloorElement: (state, action) => {
      const { editId, floorId, ...newFloor } = action.payload;
      if (action.payload?.measure?.floorInsulationProduct?.name === "geen") {
        action.payload.measure.floorInsulationProduct = null;
        action.payload.measure.floorInsulationThickness = 0;
      }
      if (action.payload?.measure?.soilInsulationProduct?.name === "geen") {
        action.payload.measure.soilInsulationProduct = null;
        action.payload.measure.soilWallSeparationLength = 0;
        action.payload.measure.soilInsulationThickness = 0;
      }
      if (editId) {
        state.data.floors = state.data.floors.filter((floor) => floor.name !== editId);
      } else {
        state.data.floors = state.data.floors.filter((floor) => floor.name !== floorId);
      }

      state.data.floors.push(newFloor);
      updateIndexedDB(current(state.data));
    },
    editRoofElement: (state, action) => {
      const { editId, roofId, ...newRoof } = action.payload;
      if (action.payload?.measure?.insulationProduct?.name === "geen") {
        action.payload.measure.insulationProduct = null;
        action.payload.measure.insulationThickness = 0;
      }
      // Trim trailing blank spaces from Roof's and Dorme's names to avoid mismatches,
      // as some names in the database contain extra spaces. This helps ensure accurate
      // matching and prevents issues caused by names with trailing whitespace.
      // Ticket #471
      if (editId) {
        state.data.roofs = state.data.roofs.filter((roof) => getTrimmedName(roof.name) !== editId);
      } else {
        state.data.roofs = state.data.roofs.filter((roof) => getTrimmedName(roof.name) !== roofId);
      }
      state.data.roofs.push(newRoof);
      updateIndexedDB(current(state.data));
    },
    editDormer: (state, action) => {
      const { roofId, dormerId, editId, ...newDormer } = action.payload;
      // Trim trailing blank spaces from Dorme's names to avoid mismatches,
      // as some names in the database contain extra spaces. This helps ensure accurate
      // matching and prevents issues caused by names with trailing whitespace.
      // Ticket #471
      if (editId) {
        state.data.roofs?.forEach((roof) => {
          roof.dormers = roof.dormers.filter((dormer) => getTrimmedName(dormer.name) !== editId);
        });
      } else {
        state.data.roofs?.forEach((roof) => {
          roof.dormers = roof.dormers.filter((dormer) => getTrimmedName(dormer.name) !== dormerId);
        });
      }

      state.data.roofs
        ?.find((roof) => getTrimmedName(roof.name) === newDormer.roofName)
        ?.dormers.push(newDormer);
      updateIndexedDB(current(state.data));
    },
    deleteDormer: (state, action) => {
      const { dormerId } = action.payload;

      const updatedRoofs = state.data.roofs.map((roof) => {
        const updatedDormers = roof.dormers.filter(
          (dormer) => getTrimmedName(dormer.name) !== dormerId,
        );
        return { ...roof, dormers: updatedDormers };
      });

      state.data.roofs = updatedRoofs;
      updateIndexedDB(current(state.data));
    },

    addWindowGroup: (state, action) => {
      const { wallId, roofId, dormerId, parentElement, ...newWindowGroup } = action.payload;
      let parsedWallId: string | undefined = undefined;
      let parsedRoofId: string | undefined = undefined;
      let parsedDormerId: string | undefined = undefined;
      let element = "";
      if (parentElement.includes("[Muren]")) {
        element = "wall";
      }
      if (parentElement.includes("[Daken]")) {
        element = "roof";
      }
      if (parentElement.includes("[Dakkapellen]")) {
        element = "dormer";
      }

      switch (element) {
        case "wall":
          parsedWallId = parentElement.replace("[Muren] ", "");
          break;
        case "roof":
          parsedRoofId = parentElement.replace("[Daken] ", "");
          break;
        case "dormer":
          parsedDormerId = parentElement.replace("[Dakkapellen] ", "");
          break;

        default:
          break;
      }

      if (action.payload?.windows) {
        action.payload.windows = action.payload?.windows?.map((window: IWindow) => {
          if (window.measure?.glassProduct?.name === "geen") window.measure.glassProduct = null;
        });
      }

      if (parsedWallId) {
        state.data.walls
          .find((wall) => wall.name === parsedWallId)
          ?.windowGroups.push(newWindowGroup);
      }
      if (parsedRoofId) {
        state.data.roofs
          .find((roof) => getTrimmedName(roof.name) === parsedRoofId)
          ?.windowGroups.push(newWindowGroup);
      }
      if (parsedDormerId) {
        state.data.roofs?.forEach((roof) =>
          roof.dormers.forEach((dormer) => {
            if (getTrimmedName(dormer.name) === parsedDormerId) {
              dormer.windowGroups.push(newWindowGroup);
              return;
            }
          }),
        );
      }
      updateIndexedDB(current(state.data));
    },
    editWindowGroup: (state, action) => {
      const { wallId, roofId, dormerId, flatId, parentElement, oldValue, ...newWindowGroup } =
        action.payload;

      const getParentElement = (element: string) => {
        if (element.includes("[Muren]")) return "wall";

        if (element.includes("[Daken]")) return "roof";

        if (element.includes("[Dakkapellen]")) return "dormer";
        return "";
      };

      const element = getParentElement(parentElement);
      const prevParentElement = getParentElement(oldValue.parentElement);

      const getParentId = (element: string, parent: string) => {
        switch (element) {
          case "wall":
            return parent.replace("[Muren] ", "");

          case "roof":
            return parent.replace("[Daken] ", "");

          case "dormer":
            return parent.replace("[Dakkapellen] ", "");

          default:
            return "";
        }
      };

      const prevParentId = getParentId(prevParentElement, oldValue.parentElement);

      /* Remove previously attached parent element*/
      if (prevParentElement === "wall") {
        state.data.walls?.map((wall) => {
          if (wall.name === prevParentId) {
            wall.windowGroups = wall.windowGroups.filter((frame) => {
              return frame.name !== flatId;
            });
          }
          return wall;
        });
      }
      // Trim trailing blank spaces from Roof's and Dorme's names to avoid mismatches,
      // as some names in the database contain extra spaces. This helps ensure accurate
      // matching and prevents issues caused by names with trailing whitespace.
      // Ticket #471
      if (prevParentElement === "roof") {
        state.data.roofs.map((roof) => {
          if (getTrimmedName(roof.name) === prevParentId) {
            roof.windowGroups = roof.windowGroups.filter((frame) => {
              return frame.name !== flatId;
            });
          }
          return roof;
        });
      }

      if (prevParentElement === "dormer") {
        state.data.roofs.map((roof) => {
          return roof.dormers.map((dormer) => {
            if (getTrimmedName(dormer.name) === prevParentId)
              dormer.windowGroups = dormer.windowGroups.filter((frame) => {
                return frame.name !== flatId;
              });
          });
        });
      }

      /** Attach new parent element */
      const newParentId = getParentId(element, parentElement);

      if (action.payload?.windows) {
        action.payload.windows = action.payload?.windows?.map((window: IWindow) => {
          if (window.measure?.glassProduct?.name === "geen") window.measure.glassProduct = null;
        });
      }

      if (element === "wall") {
        state.data.walls
          .find((wall) => wall.name === newParentId)
          ?.windowGroups.push(newWindowGroup);
      }

      if (element === "roof") {
        state.data.roofs
          .find((roof) => getTrimmedName(roof.name) === newParentId)
          ?.windowGroups.push(newWindowGroup);
      }

      if (element === "dormer") {
        state.data.roofs?.forEach((roof) =>
          roof.dormers.forEach((dormer) => {
            if (getTrimmedName(dormer.name) === newParentId) {
              dormer.windowGroups.push(newWindowGroup);
              return;
            }
          }),
        );
      }
      updateIndexedDB(current(state.data));
    },
    deleteWindowGroup: (state, action) => {
      const { wallId, roofId, dormerId, flatId, ...newWindowGroup } = action.payload;
      if (wallId) {
        state.data.walls?.forEach((wall) => {
          wall.windowGroups = wall.windowGroups.filter((frame) => frame.name !== flatId);
        });
      }
      if (roofId) {
        state.data.roofs?.forEach((roof) => {
          roof.windowGroups = roof.windowGroups.filter((frame) => frame.name !== flatId);
        });
      }
      if (dormerId) {
        state.data.roofs?.forEach((roof) =>
          roof.dormers.forEach((dormer) => {
            if (dormer.name === dormerId) {
              dormer.windowGroups = dormer.windowGroups.filter((frame) => frame.name !== flatId);
            }
          }),
        );
      }
      updateIndexedDB(current(state.data));
    },
    initEmptyInspection: (state, action) => {
      const sphId = Number(action.payload);
      const noChanges = "Er worden geen wijzigingen aangebracht!";
      const data = {
        sphId,
        creationTime: new Date().toISOString(),
        updateTime: new Date().toISOString(),
        address: "",
        accessibility: "",
        isScaffoldingRequired: false,
        hoseDistance: undefined,
        additionalRenovationVentCount: 0,
        ventilationGrilleCount: 0,
        wallDrillDiameter: "",
        wallCavityBrushCount: 0,
        errors: [],

        building: {
          constructionYear: 0,
          buildingType: undefined,
          ridgeHeight: undefined,
          ventilationType: undefined,
          storeyCount: 1,
          storeyAreas: [NaN],
          heatRecoveryInstallationType: undefined,
          heatingTemperatureClass: undefined,
          boilerType: undefined,
          errors: [],
        },

        floors: [] as IFloor[],
        walls: [] as IWall[],
        roofs: [] as IRoof[],
        atticFloor: null,
        solar: {
          images: [],
          currentPhaseConnection: undefined,
          requiredPhaseConnection: undefined,
          mainFuseBoxNeedsReplacement: false,
          mainFuseBoxLocation: undefined,
          hasFuseBoxInGarage: false,
          suggestedCableRoute: undefined,
          measures: [],
        },
        images: [],
        notes: {
          internal: "",
          ownerFloor: "",
          ownerWall: "",
          ownerRoof: "",
          ownerSolar: "",
          ownerWindows: [""],
          supplierFloor: "",
          supplierWall: "",
          supplierRoof: "",
          supplierSolar: "",
          supplierWindows: [""],
          advisor: "",
        },
        ownerPreferences: {
          floor: {
            preference: SummaryAgreement.DECLINE,
            reason: noChanges,
          },
          wall: {
            preference: SummaryAgreement.DECLINE,
            reason: noChanges,
          },
          roof: {
            preference: SummaryAgreement.DECLINE,
            reason: noChanges,
          },
          solar: {
            preference: SummaryAgreement.DECLINE,
            reason: noChanges,
          },
          windows: [
            {
              preference: SummaryAgreement.DECLINE,
              reason: noChanges,
            },
          ],
        },
      };

      state.data = data;
      openHouseDatabase(sphId);
      updateIndexedDB(data);
    },
    syncIndexedDb: (state, action: PayloadAction<IInspection>) => {
      updateIndexedDB(action.payload);
    },
    syncStateWithIndexDB: (state, action) => {
      state.data = action.payload;
    },
    setStoreyCount: (state, action) => {
      state.data.building.storeyCount = action.payload;

      const ownerWindowNotesCount = state.data.notes.ownerWindows.length;
      if (ownerWindowNotesCount === 1 && state.data.notes.ownerWindows[0].length === 0) {
        state.data.notes.ownerWindows = new Array(action.payload).fill("");
      } else if (ownerWindowNotesCount > action.payload) {
        state.data.notes.ownerWindows.splice(
          action.payload,
          ownerWindowNotesCount - action.payload,
        );
      } else if (ownerWindowNotesCount < action.payload) {
        const emptyArr = new Array(action.payload - ownerWindowNotesCount).fill("") as string[];
        state.data.notes.ownerWindows = [...state.data.notes.ownerWindows, ...emptyArr];
      }

      const supplierWindowNotesCount = state.data.notes.supplierWindows.length;
      if (supplierWindowNotesCount === 1 && state.data.notes.supplierWindows[0].length === 0) {
        state.data.notes.supplierWindows = new Array(action.payload).fill("");
      } else if (supplierWindowNotesCount > action.payload) {
        state.data.notes.supplierWindows.splice(
          action.payload,
          supplierWindowNotesCount - action.payload,
        );
      } else if (supplierWindowNotesCount < action.payload) {
        const emptyArr = new Array(action.payload - supplierWindowNotesCount).fill("") as string[];
        state.data.notes.supplierWindows = [...state.data.notes.supplierWindows, ...emptyArr];
      }
      updateIndexedDB(current(state.data));
    },
    setStoreyAreas: (state, action) => {
      state.data.building.storeyAreas = action.payload.storeyAreas;
      updateIndexedDB(current(state.data));
    },
    updateStoreyArea: (state, action) => {
      const { area, index }: { area: number; index: number } = action.payload;
      state.data.building.storeyAreas.splice(index, 1, area);
      updateIndexedDB(current(state.data));
    },
    /* 
    1. passing name and house part as props (parentElement optional)
    2. switch - case by house part
    3. find element and it's index
    4. copy the element, modify name (add duplicate-${index})
    5. save the element to store and update IndexedDB
    */
    duplicateElement: (state, action) => {
      const {
        name,
        housePart,
        parentElement,
      }: { name: string; housePart: string; parentElement?: string } = action.payload;
      let elementToDuplicate, arrLength: undefined | number;
      switch (housePart) {
        case "wall":
          elementToDuplicate = current(state.data.walls.find((el) => el.name === name));
          arrLength = current(state.data.walls).length;
          if (elementToDuplicate) {
            const duplicateElement = { ...elementToDuplicate, windowGroups: [] };
            duplicateElement.name = `${name} (kopie${arrLength})`;
            duplicateElement.images = [];
            duplicateElement.notes = "";
            state.data.walls.push(duplicateElement);
            updateIndexedDB(current(state.data));
          }
          break;
        case "wallWindowFrame":
          elementToDuplicate = current(
            state.data.walls.find((wall) =>
              wall.windowGroups.find((windowFrame) => windowFrame.name === name),
            ),
          );
          arrLength = elementToDuplicate?.windowGroups.length;
          if (elementToDuplicate && arrLength) {
            const wallName = elementToDuplicate.name;
            const windowFrame = elementToDuplicate.windowGroups.find(
              (frame) => frame.name === name,
            );
            if (windowFrame) {
              const windowFrameToDuplicate = { ...windowFrame };
              windowFrameToDuplicate.name = `${name} (kopie${arrLength})`;
              windowFrameToDuplicate.notes = "";
              windowFrameToDuplicate.images = [];
              state.data.walls
                .find((wall) => wall.name === wallName)
                ?.windowGroups.push(windowFrameToDuplicate);
              updateIndexedDB(current(state.data));
            }
          }
          break;
        case "roofWindowFrame":
          elementToDuplicate = current(
            state.data.roofs.find((roof) =>
              roof.windowGroups.find((windowFrame) => windowFrame.name === name),
            ),
          );
          arrLength = elementToDuplicate?.windowGroups.length;
          if (elementToDuplicate && arrLength) {
            const roofName = elementToDuplicate.name;
            const windowFrame = elementToDuplicate.windowGroups.find(
              (frame) => frame.name === name,
            );
            if (windowFrame) {
              const windowFrameCopy = { ...windowFrame };
              windowFrameCopy.name = `${name} (kopie${arrLength})`;
              windowFrameCopy.notes = "";
              windowFrameCopy.images = [];
              state.data.roofs
                .find((roof) => roof.name === roofName)
                ?.windowGroups.push(windowFrameCopy);
              updateIndexedDB(current(state.data));
            }
          }
          break;
        case "dormerWindowFrame":
          elementToDuplicate = current(
            state.data.roofs.find((roof) =>
              roof.dormers.find((dormer) => dormer.name === parentElement),
            ),
          );
          arrLength = elementToDuplicate?.dormers.find((dormer) => dormer.name === parentElement)
            ?.windowGroups.length;
          if (elementToDuplicate && arrLength && parentElement) {
            const windowFrame = elementToDuplicate.dormers
              .find((dormer) => dormer.name === parentElement)
              ?.windowGroups.find((frame) => frame.name === name);
            if (windowFrame) {
              const windowFrameCopy = { ...windowFrame };
              windowFrameCopy.name = `${name} (kopie${arrLength})`;
              windowFrameCopy.notes = "";
              windowFrameCopy.images = [];
              state.data.roofs.find((roof) =>
                roof.dormers
                  .find((dormer) => dormer.name === parentElement)
                  ?.windowGroups.push(windowFrameCopy),
              );
              updateIndexedDB(current(state.data));
            }
          }
          break;
        case "floor":
          elementToDuplicate = current(state.data.floors.find((el) => el.name === name));
          arrLength = current(state.data.floors).length;
          if (elementToDuplicate) {
            const duplicateElement = { ...elementToDuplicate };
            duplicateElement.name = `${name} (kopie${arrLength})`;
            duplicateElement.notes = "";
            duplicateElement.images = [];
            state.data.floors.push(duplicateElement);
            updateIndexedDB(current(state.data));
          }
          break;
        case "solarArray":
          elementToDuplicate = current(
            state.data.solar?.measures.find((solar) => solar.name === name),
          );
          arrLength = current(state.data.solar?.measures).length;
          if (elementToDuplicate) {
            const duplicateElement = { ...elementToDuplicate };
            duplicateElement.name = `${name} (kopie${arrLength})`;
            duplicateElement.images = [];
            state.data.solar.measures.push(duplicateElement);
            updateIndexedDB(current(state.data));
          }
          break;
        case "roof":
          elementToDuplicate = current(state.data.roofs.find((el) => el.name === name));
          arrLength = current(state.data.roofs).length;
          if (elementToDuplicate) {
            const duplicateElement = { ...elementToDuplicate, dormers: [], windowGroups: [] };
            duplicateElement.name = `${name} (kopie${arrLength})`;
            duplicateElement.notes = "";
            duplicateElement.images = [];
            duplicateElement.proportionAboveAtticFloor = 0;
            state.data.roofs.push(duplicateElement);
            updateIndexedDB(current(state.data));
          }
          break;
        case "dormer":
          elementToDuplicate = current(
            state.data.roofs.find((roof) => roof.dormers.find((dormer) => dormer.name === name)),
          );
          arrLength = elementToDuplicate?.dormers.length;
          if (elementToDuplicate && arrLength) {
            const dormerElement = elementToDuplicate.dormers.find((dormer) => dormer.name === name);
            if (dormerElement) {
              const dormerCopy = { ...dormerElement, images: [], windowGroups: [] };
              dormerCopy.name = `${name}_[${elementToDuplicate.name}] (kopie${arrLength})`;
              state.data.roofs
                .find((roof) => roof.name === dormerCopy.roofName)
                ?.dormers.push(dormerCopy);
              updateIndexedDB(current(state.data));
            }
          }
          break;
        default:
          break;
      }
    },
    setInitialWindowsPreferences: (state, action) => {
      const {
        storeyCount,
        storeyAdjustment,
      }: { storeyCount: number; storeyAdjustment: { isIncrement: boolean; value: number } } =
        action.payload;

      if (storeyCount > 0) {
        const existingPreferences = [...(state.data.ownerPreferences.windows || [])];

        if (storeyAdjustment.isIncrement) {
          //sotrey count increased
          const newPreferences = Array(storeyAdjustment.value).fill(
            {
              preference: SummaryAgreement.DECLINE,
              reason: t("noChanges"),
            },
            0,
            storeyCount,
          );
          state.data.ownerPreferences.windows = [...existingPreferences, ...newPreferences];
        } else {
          // storey count decreased
          state.data.ownerPreferences.windows = existingPreferences.slice(
            0,
            storeyAdjustment.value,
          );
        }

        updateIndexedDB(current(state.data));
      }
    },
    saveOwnerPreferences: (state, action: PayloadAction<SummarySchemaType>) => {
      const preferences = action.payload;
      // typedEntries wraps Object.entries to return key types
      for (const [property, val] of typedEntries(preferences)) {
        if (Array.isArray(val) && property === "windows") {
          for (const [_winKey, winVal] of Object.entries(val)) {
            if (winVal?.reason && winVal?.preference === "accept") {
              winVal.reason = "";
            }
          }
        } else if (!Array.isArray(val)) {
          // val?.reason is required because sometimes migth be undefined
          if (val?.reason && val?.preference === "accept") {
            val.reason = "";
          }
        }
      }
      let windows: { reason?: string | undefined; preference: string }[] = [];
      // preferences.windows ? (windows = Object.entries(preferences.windows)) : (windows = []);
      if (!preferences.windows) {
        windows = [];
      } else {
        windows = preferences.windows.map((el) => {
          return { reason: el?.reason, preference: el?.preference };
        });
      }
      const modifiedPreferences = {
        floor: { ...preferences.floor },
        wall: { ...preferences.wall },
        solar: { ...preferences.solar },
        roof: { ...preferences.roof },
        windows,
      };
      state.data.ownerPreferences = modifiedPreferences;
      updateIndexedDB(current(state.data));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInspection.pending, (state) => {
      state.loading = Loading.PENDING;
    });
    builder.addCase(fetchInspection.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = Loading.FULLFILLED;
      state.data = action.payload;
      updateIndexedDB(action.payload);
    });
    builder.addCase(fetchInspection.rejected, (state, action: PayloadAction<any>) => {
      state.loading = Loading.REJECTED;
      state.error = action.payload;
    });
  },
});

export const {
  saveRoof,
  deleteRoof,
  saveSolarPanel,
  saveSolarPage,
  deleteSolar,
  saveDormer,
  deleteDormer,
  saveAdditionalInspectionData,
  saveAtticFloor,
  saveNotes,
  saveWindowNotes,
  saveHouseInfo,
  updateFloorArea,
  deleteFloorArea,
  saveWall,
  deleteWall,
  saveFloorData,
  saveFloorElement,
  editFloorElement,
  deleteFloorElement,
  editRoofElement,
  editDormer,
  addWindowGroup,
  deleteWindowGroup,
  editWindowGroup,
  initEmptyInspection,
  syncStateWithIndexDB,
  deleteAtticFloor,
  setStoreyCount,
  setStoreyAreas,
  updateStoreyArea,
  duplicateElement,
  setInitialWindowsPreferences,
  saveOwnerPreferences,
} = inspectionSlice.actions;

export const getRoofsWallsDormers = (state: RootState) => {
  const elementNames: string[] = [];
  state.inspection.data?.walls?.forEach((wall) =>
    elementNames.push("[Muren] " + getTrimmedName(wall.name)),
  );
  state.inspection.data?.roofs?.forEach((roof) =>
    elementNames.push("[Daken] " + getTrimmedName(roof.name)),
  );
  state.inspection.data?.roofs?.forEach((roof) => {
    roof.dormers?.forEach((dormer) =>
      elementNames.push("[Dakkapellen] " + getTrimmedName(dormer.name)),
    );
  });
  return elementNames;
};
export const getInspectionData = (state: RootState) => {
  return {
    sphId: state.inspection.data?.sphId,
    address: state.inspection.data?.address,
    accessibility: state.inspection.data?.accessibility,
    isScaffoldingRequired: state.inspection.data?.isScaffoldingRequired,
    hoseDistance: state.inspection.data?.hoseDistance,
    additionalRenovationVentCount: state.inspection.data?.additionalRenovationVentCount,
    wallDrillDiameter: state.inspection.data?.wallDrillDiameter,
    wallCavityBrushCount: state.inspection.data?.wallCavityBrushCount,
    ventilationGrilleCount: state.inspection.data?.ventilationGrilleCount,
    errors: state.inspection.data.errors,
  };
};
export const getWallGeneralInfo = (state: RootState) => {
  return {
    isScaffoldingRequired: state.inspection.data?.isScaffoldingRequired,
    wallDrillDiameter: state.inspection.data?.wallDrillDiameter,
    wallCavityBrushCount: state.inspection.data?.wallCavityBrushCount,
    ownerWall: state.inspection.data?.notes?.ownerWall,
    supplierWall: state.inspection.data?.notes?.supplierWall,
  };
};
const getStoreys = (state: RootState) =>
  state.inspection.data?.building?.storeyAreas?.length
    ? state.inspection.data?.building.storeyAreas
    : [];
export const getStoreyAreas = createSelector([getStoreys], (storeys) =>
  storeys?.map((el, index) => index.toString()),
);
export const getHouseInfoData = (state: RootState) => {
  return {
    images: state.inspection.data?.images,
    building: state.inspection.data?.building,
    accessibility: state.inspection.data?.notes?.internal,
    hoseDistance: state.inspection.data?.hoseDistance,
    floorAreas: state.inspection.data?.building?.storeyAreas?.map((area, index) => {
      return {
        storey: index,
        storeyName: index === 0 ? "Began woonlaag" : `${index}e woonlaag`,
        area: area,
        width: 0,
        height: 0,
      };
    }),
    advisor: state.inspection?.data?.notes?.advisor,
  };
};
export const getInspectionDataLoadingStatus = (state: RootState) => state.inspection.loading;
export const getFloorsData = (state: RootState) => state.inspection.data?.floors;
export const getWallsData = (state: RootState) => state.inspection.data?.walls;
export const getRoofsData = (state: RootState) => state.inspection?.data?.roofs;
export const getNotesData = (state: RootState) => state.inspection.data?.notes;
export const getOwnerPreferencesData = (state: RootState) =>
  state.inspection.data?.ownerPreferences;
export const getAtticFloorData = (state: RootState) => {
  const roofs = state.inspection.data?.roofs
    ?.filter((roof) => roof.proportionAboveAtticFloor !== 0)
    .map((item) => {
      return { name: item.name, proportion: item.proportionAboveAtticFloor * 100 };
    });
  return { ...state.inspection.data?.atticFloor, roofs };
};
export const getSolarData = (state: RootState) => state.inspection.data?.solar;
export const getSolarByMeasure = (name: string) => (state: RootState) => {
  return state.inspection.data?.solar?.measures.find((solar) => solar.name === name);
};
export const getSolarByImages = (state: any, name: string) => {
  return state.inspection.data?.solar?.images.find((solar: any) => solar.name === name);
};
export const getRoofByName = (state: RootState, name: string) => {
  return state.inspection.data?.roofs?.find((roof) => getTrimmedName(roof.name) === name);
};

export const getDormers = (state: RootState) => {
  return state.inspection.data?.roofs?.reduce((acc: IDormer[], roof: IRoof) => {
    const newDormers = roof.dormers?.map((dormer: IDormer) => {
      return {
        ...dormer,
        roofName: roof.name,
      };
    });

    return acc.concat(newDormers);
  }, []);
};
export const getDormerByName = (state: RootState, name: string) => {
  return getDormers(state)?.find((dormer) => getTrimmedName(dormer.name) === name);
};
export const getFloorByName = (state: RootState, name: string) => {
  return getFloorsData(state)?.find((floor) => floor.name === name);
};
export const getWallByName = (name: string) => (state: RootState) =>
  state.inspection.data?.walls?.find((wall) => wall.name === name);

export const getUsableSurfaces = (state: RootState) => {
  const usableSurfaces: { floor: number; area: number }[] = [];
  state.inspection.data?.floors.forEach((floor: { area: number }, index: number) => {
    usableSurfaces.push({
      floor: index,
      area: floor.area,
    });
  });

  return usableSurfaces;
};

export const getWindowFrameByName = (
  state: RootState,
  flatId?: string,
  roofId?: string,
  wallId?: string,
  dormerId?: string,
) => {
  if (roofId) {
    const windowFrame = state.inspection.data?.roofs
      ?.find((roof) => getTrimmedName(roof.name) === roofId)
      // No need to trim wall flatId/window name in URLs
      // flatId id included in the middle segment of the URL.
      // Trailing spaces, if present in the database, will be visible and represented as %20 in the URL.
      ?.windowGroups?.find((group) => group.name === flatId);
    return windowFrame;
  }
  // Trim trailing blank spaces from Roof's and Dorme's names to avoid mismatches,
  // as some names in the database contain extra spaces. This helps ensure accurate
  // matching and prevents issues caused by names with trailing whitespace.
  // Ticket #471
  if (wallId) {
    const windowFrame = state.inspection.data?.walls
      ?.find((wall) => getTrimmedName(wall.name) === wallId)
      ?.windowGroups?.find((group) => group.name === flatId);

    return windowFrame;
  }
  if (dormerId) {
    let windowFrame = undefined;
    state.inspection.data?.roofs?.forEach((roof) =>
      roof.dormers.forEach((dormer) => {
        if (getTrimmedName(dormer.name) === dormerId) {
          windowFrame = dormer.windowGroups.find((frame) => frame.name === flatId);
        }
      }),
    );

    return windowFrame;
  }
};

const SelectWallElementsWindowGroups = (state: RootState) => {
  const walls: Pick<IWall, "name" | "errors" | "area" | "direction" | "windowGroups">[] = [];
  state.inspection?.data?.walls?.map(
    (wall: {
      name: string;
      area: number;
      direction: Direction;
      errors?: string[] | [];
      windowGroups: IWindowGroup[];
    }) =>
      walls.push({
        name: wall.name,
        area: wall.area,
        direction: wall.direction,
        errors: wall.errors,
        windowGroups: wall.windowGroups,
      }),
  );
  return walls;
};
const SelectRoofElementsWindowGroups = (state: RootState) => {
  const roofs: Pick<IRoof, "name" | "windowGroups" | "dormers">[] = [];
  state.inspection?.data?.roofs?.map(
    (roof: { name: string; windowGroups: IWindowGroup[]; dormers: IDormer[] }) =>
      roofs.push({ name: roof.name, windowGroups: roof.windowGroups, dormers: roof.dormers }),
  );
  return roofs;
};

export const getWallsAndRoof = createSelector(
  [SelectWallElementsWindowGroups, SelectRoofElementsWindowGroups],
  (walls, roofs) => {
    return { walls, roofs };
  },
);

const SelectSolarPanel = (state: RootState) => {
  const solarArr: ISolarPanel["measures"] = [];
  state.inspection.data?.solar?.measures.map((solar) => solarArr.push(solar));
  return solarArr;
};

export const getWalls = createSelector(SelectWallElementsWindowGroups, (walls) => {
  return walls;
});

export const getSolar = createSelector([SelectSolarPanel], (solarArr) => {
  return { solarArr };
});

export const getSolarCount = createSelector([SelectSolarPanel], (solarArr) => {
  return solarArr.length;
});

export const getSolarObject = (state: RootState) => {
  return state.inspection.data?.solar;
};

export const getCompletedInspection = (state: RootState) => {
  return state.inspection?.data;
};

const buildingConstructionYear = (state: RootState) =>
  state.inspection.data?.building?.constructionYear;

export const getBuildingConstructionYear = createSelector(
  [buildingConstructionYear],
  (year) => year,
);

const SelectRoofDashboard =
  (t: TFunction<"translation", undefined, "translation">) => (state: RootState) => {
    if (state.inspection.data?.roofs?.length) {
      return [
        {
          housePartName: t("roofs"),
          housePartsWithWindowGroup: state.inspection.data?.roofs?.map((roof) => {
            return {
              name: roof?.name,
              path: `/houses/${state.inspection.data.sphId}/roof/add-roof-element/${encodeURI(
                roof.name,
              )}`,
              errors: roof?.errors,
              windowFrames: roof?.windowGroups?.map((windowFrame) => {
                return {
                  name: windowFrame?.name,
                  path: `/houses/${state.inspection.data.sphId}/flat/${encodeURI(
                    windowFrame.name,
                  )}/add-window/wall/${encodeURI(roof.name)}`,
                  errors: [
                    ...(windowFrame?.errors ?? []),
                    ...windowFrame.windows.flatMap((window) => window.errors ?? []),
                  ],
                };
              }),
            };
          }),
          errors: [
            ...(state.inspection.data.roofs.flatMap((roof) => roof.errors ?? []) ?? []),
            ...state.inspection.data.roofs.flatMap(
              (roof) => roof.windowGroups.flatMap((windowFrame) => windowFrame.errors ?? []) ?? [],
            ),
            ...state.inspection.data.roofs.flatMap(
              (roof) =>
                roof.windowGroups.flatMap((windowFrame) =>
                  windowFrame.windows.flatMap((window) => window.errors ?? []),
                ) ?? [],
            ),
          ].flat(),
        },
      ];
    }
    return null;
  };

export const getRoofDashboard = (t: TFunction<"translation", undefined, "translation">) =>
  createSelector([SelectRoofDashboard(t)], (roof) => roof);

const SelectWallsDashboard =
  (t: TFunction<"translation", undefined, "translation">) => (state: RootState) => {
    if (state.inspection.data?.walls?.length) {
      return [
        {
          housePartName: t("walls"),
          errors: [
            ...(state.inspection.data.walls.flatMap((wall) => wall?.errors ?? []) ?? []),
            ...state.inspection.data.walls.flatMap(
              (wall) => wall.windowGroups.flatMap((windowFrame) => windowFrame.errors ?? []) ?? [],
            ),
            ...(state.inspection.data.errors ?? []),
          ].flat(),
          generalWallData: {
            name: t("general"),
            path: `/houses/${state.inspection.data.sphId}/wall`,
            errors: state.inspection.data.errors ?? [],
            windowFrames: [],
          },
          housePartsWithWindowGroup: state.inspection.data.walls?.map((wall) => {
            return {
              name: wall?.name,
              path: `/houses/${state.inspection.data.sphId}/wall/add-wall-element/${encodeURI(
                wall.name,
              )}`,
              errors: wall?.errors,
              windowFrames: wall?.windowGroups?.map((windowFrame) => {
                return {
                  name: windowFrame?.name,
                  path: `/houses/${state.inspection.data.sphId}/flat/${encodeURI(
                    windowFrame.name,
                  )}/add-window/wall/${encodeURI(wall.name)}`,
                  errors: windowFrame?.errors,
                };
              }),
            };
          }),
        },
      ];
    }
    return null;
  };

export const getWallsDashboard = (t: TFunction<"translation", undefined, "translation">) =>
  createSelector([SelectWallsDashboard(t)], (wall) => wall);

const SelectFloorDashboard =
  (t: TFunction<"translation", undefined, "translation">) => (state: RootState) => {
    if (state.inspection.data?.floors?.length) {
      return [
        {
          housePartName: t("floor"),
          housePartsWithWindowGroup: state.inspection.data.floors?.map((floor) => {
            return {
              name: floor?.name,
              path: `/houses/${state.inspection.data.sphId}/floor/add-floor-element/${encodeURI(
                floor.name,
              )}`,
              errors: floor?.errors,
              windowFrames: [],
            };
          }),
        },
      ];
    }
    return null;
  };

export const getFloorsDashboard = (t: TFunction<"translation", undefined, "translation">) =>
  createSelector([SelectFloorDashboard(t)], (floor) => floor);

const SelectSolarPanelsDashboard =
  (t: TFunction<"translation", undefined, "translation">) => (state: RootState) => {
    if (state.inspection.data?.solar?.measures?.length) {
      return [
        {
          housePartName: t("solarPanels"),
          generalSolarData: {
            name: t("general"),
            path: `/houses/${state.inspection?.data?.sphId}/solar`,
            errors: state.inspection.data.solar.errors ?? [],
          },
          housePartsWithWindowGroup: state.inspection.data.solar?.measures.map((measure) => {
            return {
              name: measure.name,
              path: `/houses/${state.inspection.data.sphId}/solar/add-solar-element/${encodeURI(
                measure.name,
              )}`,
              errors: measure.errors,
              windowFrames: [],
            };
          }),
        },
      ];
    }
    return null;
  };

export const getSolarDashboard = (t: TFunction<"translation", undefined, "translation">) =>
  createSelector([SelectSolarPanelsDashboard(t)], (solar) => solar);

const SelectAtticDashboard =
  (t: TFunction<"translation", undefined, "translation">) => (state: RootState) => {
    if (state.inspection.data?.atticFloor) {
      return [
        {
          housePartName: t("atticFloor"),
          housePartsWithWindowGroup: [
            {
              name: t("atticFloor"),
              path: `/houses/${state.inspection.data.sphId}/roof/add-attic-floor`,
              errors: state.inspection.data.atticFloor?.errors,
              windowFrames: [],
            },
          ],
        },
      ];
    }
    return null;
  };

export const getAtticDashboard = (t: TFunction<"translation", undefined, "translation">) =>
  createSelector([SelectAtticDashboard(t)], (attic) => attic);

const SelectGeneralDashboard =
  (t: TFunction<"translation", undefined, "translation">) => (state: RootState) => {
    if (state.inspection.data?.building) {
      const imageError = state.inspection.data?.images.length > 0 ? null : t("pictures");
      const errors: string[] = [];
      for (const [key, val] of Object.entries(state.inspection.data?.building)) {
        if (key === "constructionYear" && val === 0) errors.push(key);
        if (typeof val === "object" && !val?.length && key !== "errors") {
          errors.push(key);
        }
        if (typeof val === "object" && val?.length && isNaN(val[0])) {
          errors.push(key);
        }
        if (val === undefined) errors.push(key);
      }

      return [
        {
          housePartName: t("general"),
          notes: {
            windowSuggestionOwner: state.data.suggestions.data.filter(
              (s) => s.category === SuggestionCategory.OWNER_WINDOW,
            ),
            windowSuggestionSupplier: state.data.suggestions.data.filter(
              (s) => s.category === SuggestionCategory.EXECUTIVE_WINDOW,
            ),
            ownerWindowNotes: state.inspection.data.notes.ownerWindows,
            supplierWindowNotes: state.inspection.data.notes.supplierWindows,
          },
          housePartsWithWindowGroup: [
            {
              name: t("general"),
              path: `/houses/${state.inspection?.data?.sphId}/details`,
              errors:
                state.inspection.data?.building?.errors.length > 0
                  ? [...state.inspection.data.building.errors, imageError].filter(Boolean)
                  : [...errors, imageError].filter(Boolean),
              windowFrames: [],
            },
          ],
        },
      ];
    }
    return null;
  };

export const getGeneralDashboard = (t: TFunction<"translation", undefined, "translation">) =>
  createSelector([SelectGeneralDashboard(t)], (building) => building);

export const getInspectionDataErrors = (t: TFunction<"translation", undefined, "translation">) =>
  createSelector(
    [
      SelectRoofDashboard(t),
      SelectWallsDashboard(t),
      SelectFloorDashboard(t),
      SelectSolarPanelsDashboard(t),
      SelectAtticDashboard(t),
      SelectGeneralDashboard(t),
    ],
    (roofs, walls, floors, solar, attic, general) => {
      const errors: string[] = [];
      if (roofs?.length) {
        roofs?.forEach((roof) => {
          const mainRoofErrors = roof?.housePartsWithWindowGroup?.some(
            (el) => el.errors && el.errors.length > 0,
          );
          const windowsRoofErrors = roof?.housePartsWithWindowGroup?.some((el) =>
            el.windowFrames?.some((group) => group?.errors?.length > 0),
          );
          if (mainRoofErrors || windowsRoofErrors) {
            errors.push(t("roof"));
            return;
          }
        });
      } else errors.push(t("roof"));
      if (walls?.length) {
        walls?.forEach((wall) => {
          const mainWallErrors = wall.housePartsWithWindowGroup?.some(
            (el) => el.errors && el.errors.length > 0,
          );
          const windowsWallErrors = wall?.housePartsWithWindowGroup?.some((el) =>
            el.windowFrames?.some((frame) => frame?.errors?.length > 0),
          );
          const generalWallInfoErrors = !!wall.generalWallData.errors.length;
          if (mainWallErrors || windowsWallErrors || generalWallInfoErrors) {
            errors.push(t("wall"));
            return;
          }
        });
      } else errors.push(t("wall"));
      if (solar?.length) {
        solar?.forEach((solar) => {
          const solarErrors =
            solar?.housePartsWithWindowGroup?.some((el) => el.errors && el.errors.length > 0) ||
            !!solar.generalSolarData.errors?.length;

          if (solarErrors) {
            errors.push(t("solar"));
            return;
          }
        });
      }
      if (floors?.length) {
        floors?.forEach((floor) => {
          const floorErrors = floor?.housePartsWithWindowGroup?.some(
            (el) => el.errors && el.errors.length > 0,
          );
          if (floorErrors) {
            errors.push(t("floor"));
            return;
          }
        });
      } else errors.push(t("floor"));
      if (attic?.length) {
        attic?.forEach((attic) => {
          const atticErrors = attic?.housePartsWithWindowGroup?.some(
            (el) => el.errors && el.errors.length > 0,
          );
          if (atticErrors) {
            errors.push(t("atticFloor"));
            return;
          }
        });
      }
      if (general?.length) {
        general?.forEach((general) => {
          const ownerStoreyNotesErros = general.notes.ownerWindowNotes.some((note) => !note.length);
          const generalErrors = general?.housePartsWithWindowGroup?.some(
            (el) => el.errors && el.errors.length > 0,
          );
          if (generalErrors || ownerStoreyNotesErros) {
            errors.push(t("generalHouseInfo"));
            return;
          }
        });
      } else errors.push(t("generalHouseInfo"));
      return errors;
    },
  );

const selectSummaryData = (state: RootState) => {
  const summaryData = new Map<keyof typeof SummaryElements, SummaryData>();
  if (state.inspection.data?.floors?.length) {
    const floors = state.inspection?.data?.floors.map((floor) => {
      return {
        name: floor.name,
        insulation: floor.measure.floorInsulationProduct,
        soilInsulation: floor.measure.soilInsulationProduct,
      };
    });
    const ownerAgreements = state.inspection?.data?.notes?.ownerFloor;
    const floorsWithOwnerAgreement: SummaryData = { elements: floors, ownerAgreements };
    floors.length && summaryData.set("Floor", floorsWithOwnerAgreement);
  }
  if (state.inspection.data?.walls?.length) {
    const walls = state.inspection?.data?.walls.map((wall) => {
      return { name: wall.name, insulation: wall.measure.insulationProduct };
    });
    const ownerAgreements = state.inspection?.data?.notes?.ownerWall;
    const wallsWithOwnerAgreements = { elements: walls, ownerAgreements };
    if (walls.length) summaryData.set("Walls", wallsWithOwnerAgreements);
  }
  if (state.inspection.data?.roofs?.length) {
    const roofs = state.inspection.data?.roofs?.map((roof) => {
      return { name: roof.name, insulation: roof.measure.insulationProduct };
    });
    const ownerAgreements = state.inspection?.data?.notes?.ownerRoof;
    const atticFloor = !!state.inspection?.data?.atticFloor;
    const roofsWithOwnerAgreements = { elements: roofs, ownerAgreements, atticFloor };
    if (roofs.length) summaryData.set("Roof", roofsWithOwnerAgreements);
  }
  if (state.inspection.data?.solar?.measures.length) {
    const solar = state.inspection?.data?.solar?.measures?.map((solar) => {
      return { name: solar.name, panelCount: solar.panelCount };
    });
    const ownerAgreements = state.inspection?.data?.notes?.ownerSolar;
    const solarWithOwnerAgreements = { elements: solar, ownerAgreements };
    if (solar.length) summaryData.set("Solar", solarWithOwnerAgreements);
  }
  return summaryData;
};
export const getSummaryData = () =>
  createSelector([selectSummaryData], (summaryData) => summaryData);

const selectWindowsSummary = (state: RootState) => {
  const storeyCount = state.inspection?.data?.building?.storeyCount;

  const windowGroupsPerStorey = Array.from(
    { length: storeyCount },
    () =>
      <
        {
          windowGroups: {
            name: string;
            windowsCount: number;
            modifiedWindowsCount: number;
            insulation: boolean;
          }[];
          ownerAgreements: string;
          title: string;
        }
      >{
        ownerAgreements: "",
        windowGroups: [],
        title: "",
      },
  );

  state.inspection?.data?.walls?.forEach((wall) =>
    wall.windowGroups.forEach((windowFrame) => {
      windowGroupsPerStorey[windowFrame.storey].windowGroups?.push({
        name: windowFrame.name,
        windowsCount: windowFrame.windows.length,
        modifiedWindowsCount: Number(
          windowFrame.windows.filter((window) => window.measure.glassProduct !== null)?.length,
        ),
        insulation: windowFrame.windows.some(
          (window) =>
            window.measure.glassProduct !== null && window.measure?.glassProduct.name.length > 0,
        ),
      });
    }),
  );
  state.inspection?.data?.roofs?.forEach((roof) => {
    roof.windowGroups.forEach((windowFrame) => {
      windowGroupsPerStorey[windowFrame.storey].windowGroups?.push({
        name: windowFrame.name,
        windowsCount: windowFrame.windows.length,
        modifiedWindowsCount: Number(
          windowFrame.windows.filter((window) => window.measure.glassProduct !== null)?.length,
        ),
        insulation: windowFrame.windows.some(
          (window) =>
            window.measure.glassProduct !== null && window.measure?.glassProduct.name.length > 0,
        ),
      });
    });
    roof.dormers.forEach((dormer) =>
      dormer.windowGroups.forEach((windowFrame) => {
        windowGroupsPerStorey[windowFrame.storey]?.windowGroups?.push({
          name: windowFrame.name,
          windowsCount: windowFrame.windows.length,
          modifiedWindowsCount: Number(
            windowFrame.windows.filter((window) => window.measure.glassProduct !== null)?.length,
          ),
          insulation: windowFrame.windows.some(
            (window) =>
              window.measure.glassProduct !== null && window.measure?.glassProduct.name.length > 0,
          ),
        });
      }),
    );
  });

  for (let idx = 0; idx < state.inspection?.data?.building?.storeyCount; idx++) {
    windowGroupsPerStorey[idx].title =
      idx === 0 ? "Ruiten begane grond" : `Ruiten ${idx}e woonlaag`;
    windowGroupsPerStorey[idx].ownerAgreements =
      state.inspection?.data?.notes?.ownerWindows[idx] || "";
  }

  return windowGroupsPerStorey;
};

export const getWindowsSummary = () =>
  createSelector([selectWindowsSummary], (windowsSummary) => windowsSummary);

const SelectErrors = (state: RootState) => {
  const errors: string[] = [];
  const findError = (inspectionData: any) => {
    for (const field in inspectionData) {
      if (typeof inspectionData[field] === "object" && inspectionData[field] !== null) {
        findError(inspectionData[field]);
        if (field === "errors") {
          if (inspectionData[field].length !== 0) {
            errors.push(inspectionData[field]);
          }
        }
      }
    }
  };

  findError(state.inspection.data);

  return errors;
};

export const getErrors = () => createSelector([SelectErrors], (data) => data);

const hasElements = (state: RootState) => {
  return state.inspection.data?.walls?.length ||
    state.inspection.data?.floors?.length ||
    state.inspection.data?.solar?.measures?.length ||
    state.inspection.data?.roofs?.length ||
    state.inspection.data?.building?.constructionYear >= 1700 ||
    (state.inspection.data?.atticFloor && state.inspection.data?.atticFloor?.area > 0)
    ? true
    : false;
};

export const getDashboardHasElements = createSelector([hasElements], (hasElements) => hasElements);
