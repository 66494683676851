import { yupResolver } from "@hookform/resolvers/yup";
import { Box, MenuItem, TextField, Typography } from "@mui/material";
import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { Controller, useForm, UseFormReturn } from "react-hook-form";
import { MainButton } from "../../components/common/Button/MainButton";
import { Card } from "../../components/common/Container/Containers";
import { MainPageWrapper } from "../../components/common/Container/MainPage";
import { PageWrapper } from "../../components/common/Container/PageWrapper";
import { TwoColumnsContainer } from "../../components/common/Container/TwoColumnsContainer";
import { Cover } from "../../components/common/Cover/Cover";
import { Form } from "../../components/common/Form/Form";
import { FormInput } from "../../components/common/Form/FormInput";
import { FormStack } from "../../components/common/Form/FormStack";
import {
  AdditionalData,
  AdditionalDataWrapper,
} from "../../components/common/StepComponents/AdditionalData";
import { SurfaceArea, SurfaceAreaForm } from "../../components/common/SurfaceArea";
import EditIcon from "@mui/icons-material/Edit";
import InputAdornment from "@mui/material/InputAdornment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FormSelect } from "../../components/common/Form/FormSelect";
import { convertBlobToBase64, Photos } from "../../components/photos/Photos";
import { useAppSelector } from "../../store/hooks";
import {
  deleteRoof,
  editRoofElement,
  getCategorySpecificProducts,
  getFilteredImageTags,
  getFilteredSuggestions,
  getRoofByName,
  getRoofsData,
  getStoreyAreas,
  saveRoof,
} from "../../store/slices";

import {
  IRoof,
  OutsideFoil,
  Category,
  Direction,
  SuggestionCategory,
  RoofInsulationMaterial,
  Prefixes,
} from "../../types";
import { RootState } from "../../store/store";
import { getRoofElementSchema } from "../../types/schema";
import isNameUnique from "../../utils/helpers/nameChecker";
import { useConfirm } from "../../components/common/Dialog/ConfirmDialog";
import { toast } from "react-toastify";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import { StyledCheckbox } from "../../components/common/StepComponents/Checkbox.styled";
import { PhotoModal } from "../../components/photos/Modal";
import { fetchOrientationImage } from "../../store/thunks";
import stringToNumber from "../../utils/helpers/strToNum";
import { FailedImageUploadRequest } from "src/types/failedImageUploadRequest";
import { updateHouseImageRequestsWithNewName } from "src/utils/updateHouseImageRequestsWithNewName";
import customLocalStorage from "src/utils/localStorage";
import { getTrimmedName } from "src/utils/helpers/getTrimmedName";

const ROOF_NAME_UNKNOWN = "roof_unknown";

export type SituationProps = {
  form: UseFormReturn<IRoof & SurfaceAreaForm, any>;
  showOrientationImage?: () => void | undefined;
};

const CurrentSituation: FC<SituationProps> = ({ form, showOrientationImage }) => {
  const storeyAreas = useAppSelector(getStoreyAreas);
  const { t } = useTranslation();

  const [showSurface, setShowSurface] = useState(false);
  const {
    register,
    formState: { errors },
    watch,
    setValue,
    trigger,
    clearErrors,
  } = form;

  const currentInsulation = watch("insulationMaterial");

  const handleSelectInsulationMaterial = async (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value } = event.target;
    setValue("insulationMaterial", value as RoofInsulationMaterial);
    await trigger();

    if (value === "geen") {
      setValue("insulationThickness", 0);
      clearErrors("insulationThickness");
    }
  };

  return (
    <Card>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} pb={2}>
        <Typography variant="h2" fontWeight={600}>
          {t("currentSituation")}
        </Typography>
        <MainButton
          variant="white"
          text={t("viewOrientation")}
          onClick={showOrientationImage}
        ></MainButton>
      </Box>
      <FormStack>
        <FormInput {...register("name")} label={t("name")} error={errors.name} />

        <FormInput
          {...register("constructionYear", { valueAsNumber: true })}
          label={t("constructionYear")}
          error={errors.constructionYear}
        />

        <FormSelect
          name="direction"
          form={form}
          label={t("direction")}
          options={Object.values(Direction)}
          error={errors.direction}
          value={form.getValues("direction")}
        />

        <FormSelect
          name="storey"
          form={form}
          label={t("storey")}
          options={storeyAreas}
          error={errors.storey}
          value={form.getValues("storey") || "0"}
        />

        <FormInput
          {...register("area", { setValueAs: (v) => stringToNumber(v) })}
          label={t("surfaceArea")}
          sx={{ ".MuiInputLabel-shrink": { left: "0" } }}
          error={errors.area}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <MainButton
                  variant="white"
                  text={t("edit")}
                  icon={<EditIcon fontSize="small" sx={{ display: "flex" }} />}
                  onClick={() => setShowSurface(!showSurface)}
                />
              </InputAdornment>
            ),
          }}
        />
        {showSurface && <SurfaceArea form={form} isFloor={true} />}

        <Controller
          name={"insulationMaterial"}
          control={form.control}
          render={({ field: { ref, value } }) => (
            <TextField
              label={t("insulationMaterial")}
              variant="outlined"
              error={!!form.getFieldState("insulationMaterial").error?.message}
              ref={ref}
              onChange={handleSelectInsulationMaterial}
              value={value || ""}
              helperText={form.getFieldState("insulationMaterial").error?.message}
              select
              fullWidth
            >
              {Object.values(RoofInsulationMaterial).map((option) => (
                <MenuItem key={option} value={option} sx={{ fontSize: "25px" }}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          )}
        />

        <FormInput
          {...register("insulationThickness", { setValueAs: (v) => stringToNumber(v) })}
          label={t("insulationThickness")}
          error={errors.insulationThickness}
          disabled={currentInsulation === undefined}
        />

        <FormInput
          {...register("slope", { setValueAs: (v) => stringToNumber(v) })}
          label={t("slope")}
          error={errors.slope}
        />

        <FormInput
          {...register("visiblePurlinThickness", { setValueAs: (v) => stringToNumber(v) })}
          label={t("visiblePurlinThickness")}
          error={errors.visiblePurlinThickness}
        />

        <FormSelect
          name="outsideFoil"
          form={form}
          label={t("foilOutside")}
          options={Object.values(OutsideFoil)}
          error={errors.outsideFoil}
          value={form.getValues("outsideFoil")}
        />
      </FormStack>
    </Card>
  );
};

const NewSituation: FC<
  SituationProps & { insulationMaterials: { id: string; name: string }[] }
> = ({ form, insulationMaterials }) => {
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
  } = form;
  const possibleInsulation = watch("measure.insulationProduct.name");

  const [kneeBulkheads, setKneeBulkHeads] = useState(
    (typeof form.getValues("measure.kneeWallHeight") === "number" &&
      form.getValues("measure.kneeWallHeight") !== 0) ||
      form.getValues("measure.kneeWallHatchCount") !== 0
      ? true
      : false,
  );

  useEffect(() => {
    if (possibleInsulation === "geen") {
      setValue("measure.insulationThickness", 0);
      clearErrors("measure.insulationThickness");
    }
  }, [clearErrors, possibleInsulation, setValue]);

  return (
    <Card height="98%">
      <Box height={40} display="flex" alignItems="center" mb={2}>
        <Typography variant="h2" fontWeight={600}>
          {t("newSituation")}
        </Typography>
      </Box>

      <FormStack>
        <FormSelect
          name={"measure.insulationProduct.name"}
          form={form}
          label={t("insulationMaterial")}
          options={insulationMaterials.map((el) => el.name)}
          error={errors.measure?.insulationProduct?.name}
        />

        <FormInput
          {...register("measure.insulationThickness", { setValueAs: (v) => stringToNumber(v) })}
          label={t("extraInsulation")}
          error={errors.measure?.insulationThickness}
          disabled={possibleInsulation === undefined || possibleInsulation === "geen"}
        />

        <FormControlLabel
          label={t("kneeBulkheads")}
          control={
            <StyledCheckbox
              disabled={form.getValues("measure.insulationProduct.name") === undefined}
              checked={kneeBulkheads || false}
              onChange={() => {
                setKneeBulkHeads(!kneeBulkheads);
                form.setValue("kneeBulkhead", !kneeBulkheads);
                if (!kneeBulkheads === false) {
                  form.setValue("measure.kneeWallHeight", 0);
                  form.setValue("measure.kneeWallHatchCount", 0);
                }
              }}
            />
          }
        />

        <FormInput
          {...register("measure.kneeWallHeight", { setValueAs: (v) => stringToNumber(v) })}
          label={t("heightKneeShots")}
          error={errors.measure?.kneeWallHeight}
          disabled={kneeBulkheads === false}
        />

        <FormInput
          {...register("measure.kneeWallHatchCount", { setValueAs: (v) => stringToNumber(v) })}
          label={t("numberOfShutters")}
          error={errors.measure?.kneeWallHatchCount}
          disabled={kneeBulkheads === false}
        />
      </FormStack>
    </Card>
  );
};

export const AddRoofElement: FC = () => {
  const [orientationImage, setOrientationImage] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const { roofId, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const roofElements = useSelector(getRoofsData);
  const roof = useAppSelector((state: RootState) => getRoofByName(state, roofId || ""));
  const sphId = id ? id : "";

  const insulationMaterials = useAppSelector(getCategorySpecificProducts(Category.ROOF));

  const formDefaultValues = {
    ...roof,
    insulationMaterial: roof?.insulationMaterial,
    insulationThickness: roof?.insulationThickness || 0,
    direction: roof?.direction,
    storey: roof?.storey || "0",
    outsideFoil: roof?.outsideFoil,
    measure: {
      insulationProduct: {
        id: roof?.measure?.insulationProduct?.id,
        name:
          roof?.measure.insulationProduct === null
            ? "geen"
            : roof?.measure.insulationProduct?.name || "",
      },
      insulationThickness: roof?.measure?.insulationThickness,
      kneeWallHeight: roof?.measure?.kneeWallHeight || 0,
      kneeWallHatchCount: roof?.measure?.kneeWallHatchCount || 0,
    },
    dormers: roof?.dormers || [],
    images: roof?.images || [],
    notes: roof?.notes || "",
    windowGroups: roof?.windowGroups || [],
    proportionAboveAtticFloor: roof?.proportionAboveAtticFloor || 0,
  };

  const form = useForm<IRoof & SurfaceAreaForm>({
    resolver: yupResolver(getRoofElementSchema(t)),
    mode: "all",
    defaultValues: formDefaultValues,
  });

  const {
    formState: { errors },
    trigger,
    watch,
  } = form;

  const tags = useAppSelector(getFilteredImageTags(Category.ROOF));
  const name = getTrimmedName(watch("name"));
  const roofSuggestionExecutive = useAppSelector(
    getFilteredSuggestions(SuggestionCategory.EXECUTIVE_ROOF),
  );

  const getOrientationImage = useCallback(async () => {
    const imageName = process.env.REACT_APP_ORIENTATION_IMAGE || "orientation.jpeg";
    const data = await fetchOrientationImage(+sphId, imageName);
    const blobToImg: string = data ? ((await convertBlobToBase64(data.file)) as string) : "";
    if (data) setOrientationImage(blobToImg);
  }, [sphId]);

  const showOrientationImage = () => setIsOpen(!isOpen);

  useEffect(() => {
    (async () => await getOrientationImage())();
  }, []);

  const handleSave = async () => {
    await trigger();
    const selectedNewInsulationProduct = form.getValues("measure.insulationProduct.name");
    const newInsulationProduct = insulationMaterials.find(
      (el) => el.name === selectedNewInsulationProduct,
    );
    newInsulationProduct && form.setValue("measure.insulationProduct.id", newInsulationProduct.id);
    if ("name" in form.formState.errors || "name" in form.formState.errors) {
      toast.error(t("mandatoryField"));
      form.setFocus("name");
      return;
    }

    if (name !== roofId && isNameUnique(roofElements, name)) {
      toast.error(t("uniqueName"));
      form.setFocus("name");
      return;
    }

    if (roofId) {
      dispatch(
        editRoofElement({
          ...form.getValues(),
          name,
          storey: +form.getValues("storey"),
          errors: Object.keys(form.formState.errors),
          roofId,
        }),
      );
    } else {
      dispatch(
        saveRoof({
          ...form.getValues(),
          name,
          storey: +form.getValues("storey"),
          errors: Object.keys(form.formState.errors),
        }),
      );
    }

    // Determine the name of the element, defaulting to "roof_unknown" if  'roof.name' is undefined
    const previousRoofName = roof?.name ?? ROOF_NAME_UNKNOWN;
    // Update image requests in local storage where elementName or parentName mathches with the new 'previousRoofName'

    updateHouseImageRequestsWithNewName(sphId, previousRoofName, name, true);

    toast.success(t("success"));
    navigate(-1);
  };

  useEffect(() => {
    trigger();
  }, [trigger]);

  const handleDelete = async () => {
    const choice = await confirm({
      title: t("deleteRoof"),
      description:
        roof?.dormers?.length === 0 && roof?.windowGroups?.length === 0
          ? t("deleteRoof")
          : `${t("roofHas")} ${roof?.windowGroups?.length} ${t("windowFrames")} ${t("and")} ${
              roof?.dormers?.length
            } ${t("dormer").toLowerCase()}. ${t("deleteTheseAsWell")}`,
      confirmBtnLabel: t("delete"),
    });

    if (choice) {
      dispatch(deleteRoof({ roofId }));
      // Remove image requests from local storage for the deleted roof element if they exist
      deleteHouseImageRequestFromLocalStorage();
      navigate(-1);
    }
  };

  const deleteHouseImageRequestFromLocalStorage = () => {
    const requests = customLocalStorage.getItem(`${Prefixes.HOUSE_IMAGES}${sphId}`);
    // Determine the name of the element, defaulting to "roof_unknown" if  'roof.name' is undefined
    const roofName = roof?.name ?? ROOF_NAME_UNKNOWN;

    //Extract dormer names from 'roof.dormers'
    const dormerNames =
      roof?.dormers.map((dormer) => {
        return dormer.name;
      }) ?? [];

    // Filter out requests where elementName or parentName matches 'roofName' or is included in 'dormerNames'
    const updatedRequests = requests.filter(
      (item: FailedImageUploadRequest) =>
        item.elementName !== roofName &&
        item.parentName !== roofName &&
        !dormerNames.includes(item.elementName) &&
        !dormerNames.includes(item.parentName),
    );

    customLocalStorage.setItem(`${Prefixes.HOUSE_IMAGES}${sphId}`, JSON.stringify(updatedRequests));
  };

  return (
    <MainPageWrapper>
      <Cover
        title={!roofId ? t("addRoof") : t("editRoof")}
        showBackButton={true}
        handleDelete={roofId ? handleDelete : undefined}
      />
      <PageWrapper>
        <Form>
          <TwoColumnsContainer
            childrenFirstColumn={
              <CurrentSituation form={form} showOrientationImage={showOrientationImage} />
            }
            childrenSecondColumn={
              <NewSituation form={form} insulationMaterials={insulationMaterials} />
            }
          />
          <Photos
            tags={tags}
            sphId={sphId}
            form={form}
            housePartElement="roofs"
            elementName={roof?.name ?? ROOF_NAME_UNKNOWN}
          />

          <AdditionalDataWrapper title={t("additionalExecutiveComment")}>
            <AdditionalData
              name="notes"
              form={form}
              suggestions={roofSuggestionExecutive}
              error={errors.notes}
            />
          </AdditionalDataWrapper>

          <MainButton
            text={t("save")}
            variant="primary"
            sx={{ margin: "30px 0" }}
            onClick={handleSave}
          />
        </Form>
        <PhotoModal
          imageSrc={orientationImage}
          isOpen={isOpen}
          handlePhotoModal={showOrientationImage}
        />
      </PageWrapper>
    </MainPageWrapper>
  );
};
