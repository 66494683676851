import { Direction, IDormer, IImage, IProduct, IWindowGroup } from ".";

export enum RoofInsulationMaterial {
  NONE = "geen",
  UNKNOWN = "onbekend",
  MINERAL_WOOL = "minerale wol",
  PU_FOAM = "PU schuim",
  REFLECTIVE_FOIL = "reflecterende folie",
}

export enum OutsideFoil {
  PRESENCE_UNKNOWN = "aanwezigheid onbekend",
  ABSENT = "afwezig",
  VAPOUR_PERMEABLE = "dampopen",
  VAPOUR_TIGHT = "dampdicht",
  FOIL_TYPE_UNKNOWN = "folietype onbekend",
}

export enum RoofingType {
  WAVY_TILES = "dakpannen golvend",
  FLAT_TILES = "dakpannen plat",
  SLATE = "leien",
  METAL_PLATES = "metaalplaten",
  CORRUGATED_IRON = "golfplaten",
  BITUMEN = "bitumen (schuin dak)",
  FLAT_ROOF = "bitumen/EPDM/PVC (plat dak)",
  SEDUM = "sedum",
}

export interface IRoof {
  name: string;
  notes: string;
  constructionYear: number;
  storey: number | string;
  direction: Direction;
  area: number;
  slope: number;
  insulationMaterial: RoofInsulationMaterial;
  insulationThickness: number;
  visiblePurlinThickness: number;
  outsideFoil: OutsideFoil;
  hasAtticFloor: boolean;
  windowGroups: IWindowGroup[];
  dormers: IDormer[];
  images: IImage[];
  errors?: string[];
  kneeBulkhead?: boolean;
  proportionAboveAtticFloor: number;

  measure: {
    insulationProduct: IProduct | null;
    insulationThickness: number;
    kneeWallHeight: number;
    kneeWallHatchCount: number;
  };
}

export interface IAtticFloor {
  area: number;
  notes: string;
  ridgeHeight: number;
  images: IImage[];
  errors?: string[];
  numberOfRoofSections?: number;

  measure: {
    insulationProduct: IProduct | null;
    insulationThickness: number;
  };
}
