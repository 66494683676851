import { Box, FormControlLabel, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Card } from "../../components/common/Container/Containers";
import { PageWrapper } from "../../components/common/Container/PageWrapper";
import { TwoColumnsContainer } from "../../components/common/Container/TwoColumnsContainer";
import {
  AdditionalData,
  AdditionalDataWrapper,
} from "../../components/common/StepComponents/AdditionalData";
import { MainPageWrapper } from "../../components/common/Container/MainPage";
import { Cover } from "../../components/common/Cover/Cover";
import { SurfaceArea, SurfaceAreaForm } from "../../components/common/SurfaceArea";
import { MainButton } from "../../components/common/Button/MainButton";
import { useForm, UseFormReturn } from "react-hook-form";
import { FormInput } from "../../components/common/Form/FormInput";
import { Form } from "../../components/common/Form/Form";
import { FormStack } from "../../components/common/Form/FormStack";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormSelect } from "../../components/common/Form/FormSelect";
import InputAdornment from "@mui/material/InputAdornment";
import EditIcon from "@mui/icons-material/Edit";
import {
  deleteAtticFloor,
  getAtticFloorData,
  getCategorySpecificProducts,
  getFilteredImageTags,
  getFilteredSuggestions,
  getRoofsData,
  saveAtticFloor,
} from "../../store/slices";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IAtticFloor, Category, SuggestionCategory, Prefixes, IRoof } from "../../types";
import { Photos } from "../../components/photos/Photos";
import { useAppSelector } from "../../store/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { getAtticFloorSchema } from "../../types/schema";
import { useTranslation } from "react-i18next";
import { useConfirm } from "../../components/common/Dialog/ConfirmDialog";
import stringToNumber from "../../utils/helpers/strToNum";
import { FailedImageUploadRequest } from "src/types/failedImageUploadRequest";
import customLocalStorage from "src/utils/localStorage";
import { toast } from "react-toastify";
import { parseNumber } from "src/utils/helpers/parseNumber";
import { StyledCheckbox } from "src/components/common/StepComponents/Checkbox.styled";

export type AtticSituationProps = {
  form: UseFormReturn<
    IAtticFloor & SurfaceAreaForm & { roofs: { name: string; proportion: number }[] },
    any
  >;
};

const CurrentSituation: FC<AtticSituationProps> = ({ form }) => {
  const [showSurface, setShowSurface] = useState(false);
  const [takenRoofs, setTakenRoofs] = useState<{ name: string; proportion: number }[]>([]);
  const [isAtticFloorFullyBellow, setIsAtticFloorFullyBellow] = useState<boolean>(false);
  const roofsData = useAppSelector(getRoofsData);
  const [percentageToolOpen, setPercentageToolOpen] = useState<boolean[]>([]);

  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = form;
  const roofs = form.watch("roofs");

  const [numberOfTakenRoofs, setNumberOfTakenRoofs] = useState<string>(roofs.length.toString());

  const handleNumberOfRoofsChange = (value: number) => {
    setNumberOfTakenRoofs(value.toString());
    setPercentageToolOpen(Array(value).fill(false));
    if (+value > 9) {
      form.setError("numberOfRoofSections", {
        message: `Aantal dakdelen boven de zoldervloer must be less than or equal to ${9}`,
      });
      return;
    }
    form.trigger();
    if (numberOfTakenRoofs === null) return;
    if (value === roofs.length) return form.trigger();

    if (value > 2) toast.info(t("atticRoofRequiredFields"));

    if (value < roofs.length) {
      setTakenRoofs((takenRoofs ?? []).splice(0, value));
      form.setValue("roofs", roofs.splice(0, value));
      return form.trigger();
    }

    if (value > roofs.length) {
      const newValues = Array(value - roofs.length).fill({
        name: "",
        proportion: isAtticFloorFullyBellow ? 100 : 50,
      });
      form.setValue("roofs", [...(roofs ?? []), ...newValues]);
      setTakenRoofs([...takenRoofs, ...newValues]);
      return form.trigger();
    }
  };

  const getAvailableRoofs = (roofName: string) => {
    const data = (roofsData ?? []).filter(
      (roof) => !(roofs ?? []).some((data) => data.name === roof.name),
    );

    return [{ name: roofName }, ...data];
  };

  const handlePercentageAboveAtticFloorChange = (value: number, roofName: string) => {
    if (!value) {
      const updatedRoofs = roofs.map((roof) =>
        roof.name === roofName ? { proportion: 0, name: "" } : roof,
      );

      form.setValue("roofs", updatedRoofs);
      form.trigger();
    }
  };
  const handleSelectedRoofChange = (index: number) => {
    const proportion = roofs[index].proportion;
    if (!proportion)
      return form.setError(`roofs.${index}.proportion`, {
        message: `roofs[${index}].proportion must be greater than or equal to 1`,
      });
  };

  const handleOnAtticFloorFullyBellowBtnClick = () => {
    setIsAtticFloorFullyBellow(!isAtticFloorFullyBellow);
    const updatedRoofs = roofs.map((roof) => {
      return { ...roof, proportion: isAtticFloorFullyBellow ? 50 : 100 };
    });
    form.setValue("roofs", updatedRoofs);
    form.trigger();
  };

  const handlePercentageToolVisibility = (index: number) => {
    const temp = [...percentageToolOpen];
    temp[index] = !temp[index];

    setPercentageToolOpen(temp);
  };
  return (
    <Card>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h2" fontWeight={600}>
          {t("currentSituation")}
        </Typography>
      </Box>
      <FormStack mt={4}>
        <FormInput
          {...register("area", { setValueAs: (v) => stringToNumber(v) })}
          label={t("atticArea")}
          error={errors.area}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <MainButton
                  variant="white"
                  text={t("edit")}
                  icon={<EditIcon fontSize="small" sx={{ display: "flex" }} />}
                  onClick={() => setShowSurface(!showSurface)}
                />
              </InputAdornment>
            ),
          }}
        />
        {showSurface && <SurfaceArea form={form} />}
        <FormInput
          {...register("ridgeHeight", { setValueAs: (v) => stringToNumber(v) })}
          InputLabelProps={{ shrink: true }}
          label={t("ridgeHeightAttic")}
          error={errors.ridgeHeight}
        />
        <FormInput
          value={numberOfTakenRoofs}
          onChange={(e) => {
            form.setValue("numberOfRoofSections", +e.target.value);
            setNumberOfTakenRoofs(e.target.value);
          }}
          label={t("atticFloorNumberOfRoofs")}
          error={errors?.numberOfRoofSections}
          InputLabelProps={{ shrink: true }}
          onFocus={() => setNumberOfTakenRoofs("")}
          onBlur={() => {
            handleNumberOfRoofsChange(
              numberOfTakenRoofs === "" ? roofs.length : +numberOfTakenRoofs,
            );
          }}
        />
        <FormControlLabel
          label={t("atticFloorFullyBellowRoofParts")}
          style={{ width: "fit-content" }}
          control={
            <StyledCheckbox
              checked={isAtticFloorFullyBellow || false}
              onChange={handleOnAtticFloorFullyBellowBtnClick}
            />
          }
        />
        <>
          {(roofs ?? []).map((roof, index) => (
            <FormStack direction={"row"} key={`${index}-${roof.name}`} mb={2}>
              <FormSelect
                {...register(`roofs.${index}.name`)}
                form={form}
                label={t("selectRoofpart")}
                options={getAvailableRoofs(roof.name).map((roof) => roof.name)}
                error={errors.roofs?.[index]?.name}
                InputLabelProps={{ shrink: true }}
                onRoofInAtticFloorChange={() => handleSelectedRoofChange(index)}
              />
              <FormStack style={{ width: "100%" }}>
                <FormInput
                  {...register(`roofs.${index}.proportion`)}
                  label={t("%aboveAtticFloor")}
                  error={errors.roofs?.[index]?.proportion}
                  InputLabelProps={{ shrink: true }}
                  disabled={isAtticFloorFullyBellow}
                  onChange={(e) =>
                    handlePercentageAboveAtticFloorChange(+e.target.value, roof.name)
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <MainButton
                          variant="white"
                          text={t("edit")}
                          icon={<EditIcon fontSize="small" sx={{ display: "flex" }} />}
                          onClick={() => handlePercentageToolVisibility(index)}
                        />
                      </InputAdornment>
                    ),
                  }}
                />

                {percentageToolOpen[index] && !isAtticFloorFullyBellow && (
                  <AtticArea form={form} index={index} key={index} />
                )}
              </FormStack>
            </FormStack>
          ))}
        </>
      </FormStack>
    </Card>
  );
};

const NewSituation: FC<
  AtticSituationProps & { insulationMaterials: { id: string; name: string }[] }
> = ({ form, insulationMaterials }) => {
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
    watch,
    clearErrors,
    setValue,
  } = form;

  const possibleInsulation = watch("measure.insulationProduct.name");

  useEffect(() => {
    if (possibleInsulation === "geen") {
      setValue("measure.insulationThickness", 0);
      clearErrors("measure.insulationProduct");
      clearErrors("measure.insulationThickness");
    }
  }, [clearErrors, possibleInsulation, setValue]);

  return (
    <Card height="93%">
      <Box display="flex" alignItems="center">
        <Typography variant="h2" fontWeight={600}>
          {t("newSituation")}
        </Typography>
      </Box>

      <FormStack pt={4}>
        <FormSelect
          name={"measure.insulationProduct.name"}
          form={form}
          label={t("insulationMaterial")}
          options={insulationMaterials.map((el) => el.name)}
          error={errors.measure?.insulationProduct?.name}
          InputLabelProps={{ shrink: true }}
        />

        <FormInput
          {...register("measure.insulationThickness", { setValueAs: (v) => stringToNumber(v) })}
          label={t("insulationThickness")}
          error={errors.measure?.insulationThickness}
          disabled={possibleInsulation === undefined || possibleInsulation === "geen"}
          InputLabelProps={{ shrink: true }}
        />
      </FormStack>
    </Card>
  );
};

const AtticArea: FC<any> = ({ form, index }) => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = form;
  const roofLength = watch(`roofLength.${index}`);
  const lengthAbove = watch(`lengthAbove.${index}`);
  const lengthUnder = watch(`lengthUnder.${index}`);
  const calculateSurfaceArea = (roofLength: string, lengthAbove: string, lengthUnder: string) => {
    const roofLengthInMeters = roofLength !== "" ? parseNumber(roofLength) : null;
    const lengthAboveInMeters = lengthAbove !== "" ? parseNumber(lengthAbove) : null;
    const lengthUnderInMeters = lengthUnder ? parseNumber(lengthUnder) : null;

    const calculateAndSet = (result: number) => {
      setValue(`roofs.${index}.proportion`, (+result * 100).toFixed(2));
      return form.trigger();
    };

    if (
      roofLengthInMeters !== null &&
      lengthAboveInMeters !== null &&
      lengthUnderInMeters !== null
    ) {
      //  1 - C / A = %
      const result = 1 - lengthUnderInMeters / roofLengthInMeters;
      calculateAndSet(result);
    }
    if (roofLengthInMeters !== null && lengthAboveInMeters !== null) {
      // B / A = %
      const result = lengthAboveInMeters / roofLengthInMeters;
      calculateAndSet(result);
    }
    if (roofLengthInMeters !== null && lengthUnderInMeters !== null) {
      // 1 - C / A = %
      const result = 1 - lengthUnderInMeters / roofLengthInMeters;
      calculateAndSet(result);
    }
    if (
      lengthAboveInMeters !== null &&
      lengthUnderInMeters !== null &&
      roofLengthInMeters === null
    ) {
      // B / (B + C) = %
      const result = lengthAboveInMeters / (lengthAboveInMeters + lengthUnderInMeters);
      calculateAndSet(result);
    }
  };
  const { t } = useTranslation();

  return (
    <FormStack pt={2}>
      <FormInput
        {...register(`roofLength.${index}`, {
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            return setValue(`roofLength.${index}`, e.target.value);
          },
        })}
        label={t("atticRoofLength")}
        onBlur={() => calculateSurfaceArea(roofLength, lengthAbove, lengthUnder)}
      />
      <FormInput
        {...register(`lengthAbove.${index}`, {
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            calculateSurfaceArea(roofLength, e.target.value, lengthUnder);
            return setValue(`lengthAbove.${index}`, e.target.value);
          },
        })}
        label={t("atticLenghtAbove")}
      />
      <FormInput
        {...register(`lengthUnder.${index}`, {
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            calculateSurfaceArea(roofLength, lengthAbove, e.target.value);
            return setValue(`lengthUnder.${index}`, e.target.value);
          },
        })}
        label={t("atticLengthUnder")}
      />
    </FormStack>
  );
};

export const AddAtticFloor: FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const floor = useSelector((state: RootState) => getAtticFloorData(state));
  const confirm = useConfirm();
  const sphId = id ? id : "";
  const insulationMaterials = useAppSelector(getCategorySpecificProducts(Category.ATTIC_FLOOR));

  const formDefaultValues = {
    area: floor?.area,
    notes: floor?.notes || "",
    ridgeHeight: floor?.ridgeHeight,
    measure: {
      insulationProduct: {
        id: floor?.measure?.insulationProduct?.id,
        name:
          floor?.measure?.insulationProduct === null
            ? "geen"
            : floor?.measure?.insulationProduct?.name || "",
      },
      insulationThickness: floor?.measure?.insulationThickness,
    },
    images: floor?.images || [],
    roofs: floor?.roofs || [],
    numberOfRoofSections: floor?.roofs?.length || 0,
  };

  const form = useForm<
    IAtticFloor & SurfaceAreaForm & { roofs: { name: string; proportion: number }[] }
  >({
    resolver: yupResolver(getAtticFloorSchema(t)),
    mode: "all",
    defaultValues: formDefaultValues,
  });
  const {
    formState: { errors },
    trigger,
    handleSubmit,
  } = form;

  const tags = useAppSelector(getFilteredImageTags(Category.ATTIC_FLOOR));

  const roofSuggestionExecutive = useAppSelector(
    getFilteredSuggestions(SuggestionCategory.EXECUTIVE_ROOF),
  );

  const handleSave = async () => {
    await trigger();
    const errors = Object.keys(form.formState.errors);
    if (errors.includes("roofs")) return toast.error("Need to fill in fields related to roofs");
    const selectedInsulationProduct = form.getValues("measure.insulationProduct.name");
    const selectedInsulationProductId = insulationMaterials.find(
      (el) => el.name === selectedInsulationProduct,
    );
    selectedInsulationProductId &&
      form.setValue("measure.insulationProduct.id", selectedInsulationProductId.id);
    dispatch(saveAtticFloor({ ...form.getValues(), errors: Object.keys(form.formState.errors) }));

    navigate(-1);
  };

  const handleDelete = async () => {
    const choice = await confirm({
      title: t("delete"),
      description: t("deleteAtticFloor"),
      confirmBtnLabel: t("delete"),
    });

    if (choice) {
      dispatch(deleteAtticFloor());
      deleteHouseImageRequestsFromLocalStorage();
      navigate(-1);
    }
  };

  const deleteHouseImageRequestsFromLocalStorage = () => {
    const requests = customLocalStorage.getItem(`${Prefixes.HOUSE_IMAGES}${sphId}`);

    // Filter out image requests from local storage where housePartElement matches 'atticFloor'
    const updatedRequests = requests.filter(
      (item: FailedImageUploadRequest) => item.housePartElement !== "atticFloor",
    );
    customLocalStorage.setItem(`${Prefixes.HOUSE_IMAGES}${sphId}`, JSON.stringify(updatedRequests));
  };

  useEffect(() => {
    form.reset(formDefaultValues);
    trigger();
  }, [trigger]);
  return (
    <MainPageWrapper>
      <Cover
        title={t("addAtticFloor")}
        showBackButton={true}
        handleDelete={floor ? handleDelete : undefined}
      />
      <PageWrapper>
        <Form onSubmit={handleSubmit(handleSave, handleSave)}>
          <TwoColumnsContainer
            childrenFirstColumn={<CurrentSituation form={form} />}
            childrenSecondColumn={
              <NewSituation form={form} insulationMaterials={insulationMaterials} />
            }
          />

          <Photos tags={tags} sphId={sphId} form={form} housePartElement="atticFloor" />

          <AdditionalDataWrapper title={t("additionalExecutiveComment")}>
            <AdditionalData
              name="notes"
              form={form}
              suggestions={roofSuggestionExecutive}
              error={errors.notes}
            />
          </AdditionalDataWrapper>

          <MainButton text={t("save")} variant="primary" type="submit" sx={{ margin: "30px 0" }} />
        </Form>
      </PageWrapper>
    </MainPageWrapper>
  );
};
