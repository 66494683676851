import { FC, useEffect } from "react";
import { PageWrapper } from "../../components/common/Container/PageWrapper";
import { PlusIcon } from "../../components/common/Icons";
import {
  AdditionalDataWrapper,
  AdditionalData,
} from "../../components/common/StepComponents/AdditionalData";
import { Path, useNavigation } from "../../routes";
import { MainPageWrapper } from "../../components/common/Container/MainPage";
import { Cover } from "../../components/common/Cover/Cover";
import { Card, CardSection, EmptyContainer } from "../../components/common/Container/Containers";
import { MainButton } from "../../components/common/Button/MainButton";
import HomeIcon from "../../assets/icons/home_icon.svg";
import {
  duplicateElement,
  getAtticFloorData,
  getDormers,
  getFilteredSuggestions,
  getNotesData,
  getRoofsData,
  saveNotes,
} from "../../store/slices";
import { IRoof } from "../../types/roof";
import { useAppSelector } from "../../store/hooks";
import { SectionTitle } from "../../components/common/StepComponents/StepContainers";
import { IDormer, SuggestionCategory } from "../../types";
import { Divider, Stack } from "@mui/material";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { Form } from "../../components/common/Form/Form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { DividerStack } from "../../components/common/StepComponents/StepContainers.styled";
import { getRoofSchema } from "../../types/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

type RoofPartsProps = {
  element: IDormer | IRoof;
  handleClick: () => void;
  handleDuplicate?: () => void;
};

const RoofParts: FC<RoofPartsProps> = ({ element, handleClick, handleDuplicate }) => {
  return (
    <CardSection
      title={element.name}
      errors={element.errors || []}
      area={element.area}
      direction={element.direction}
      icon={HomeIcon}
      path={"/"}
      handleClick={handleClick}
      handleDuplicate={handleDuplicate}
    />
  );
};

export const Roof: FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigation();
  const roofElements = useAppSelector(getRoofsData);
  const dormers = useAppSelector(getDormers);
  const atticFloor = useAppSelector(getAtticFloorData);
  const notes = useAppSelector(getNotesData);

  const roofSuggestionsOwner = useAppSelector(
    getFilteredSuggestions(SuggestionCategory.OWNER_ROOF),
  );
  const roofSuggestionExecutive = useAppSelector(
    getFilteredSuggestions(SuggestionCategory.EXECUTIVE_ROOF),
  );

  const form = useForm<{ ownerRoof: string; supplierRoof: string }>({
    mode: "all",
    resolver: yupResolver(getRoofSchema(t)),
    defaultValues: { ownerRoof: notes?.ownerRoof || "", supplierRoof: notes?.supplierRoof || "" },
  });

  const {
    formState: { errors },
    trigger,
    handleSubmit,
  } = form;

  useEffect(() => {
    trigger();
  }, [trigger]);

  const handleSave = async () => {
    if ("ownerRoof" in form.formState.errors) {
      toast.error(t("mandatoryField"));
      form.setFocus("ownerRoof");
      return;
    }
    dispatch(saveNotes({ ...form.getValues() }));
    navigate(`/houses/${id}/floor`);
  };

  const handleDuplicate = (dormerName: string, housePart: string) =>
    dispatch(duplicateElement({ name: dormerName, housePart }));

  useEffect(() => {
    form.reset({
      ownerRoof: notes?.ownerRoof || "",
      supplierRoof: notes?.supplierRoof || "",
    });
  }, [notes]);

  const checkIfAtticFloorObjectIsEmpty = Object.keys(atticFloor).join() === "roofs";
  return (
    <MainPageWrapper>
      <Cover title={t("roof")} />
      <PageWrapper>
        <Stack marginTop={3}>
          <SectionTitle title={t("roofs")}>
            <MainButton
              icon={<PlusIcon color="white" width="10px"></PlusIcon>}
              text={t("add")}
              variant="secondary"
              onClick={() => navigate(Path.AddRoofElement)}
            />
          </SectionTitle>
          {roofElements && roofElements.length === 0 ? (
            <EmptyContainer title={t("noRoofAdded")} />
          ) : (
            <Card sx={{ px: 0 }}>
              <DividerStack divider={<Divider />} spacing={2}>
                {roofElements &&
                  roofElements.length > 0 &&
                  roofElements?.map((element) => (
                    <RoofParts
                      key={element.name}
                      element={element}
                      handleClick={() => navigate(Path.AddRoofElement, { roofId: element?.name })}
                      handleDuplicate={() => handleDuplicate(element.name, "roof")}
                    />
                  ))}
              </DividerStack>
            </Card>
          )}
          <SectionTitle title={t("dormers")} sx={{ mt: 5 }}>
            <MainButton
              text={t("add")}
              variant="secondary"
              icon={<PlusIcon color="white" width="10px"></PlusIcon>}
              onClick={() => navigate(Path.AddDormer)}
            />
          </SectionTitle>
          {dormers && dormers.length === 0 ? (
            <EmptyContainer title={t("noDormerAdded")} />
          ) : (
            <Card sx={{ px: 0 }}>
              <DividerStack divider={<Divider />} spacing={2}>
                {dormers &&
                  dormers.length > 0 &&
                  dormers?.map((element: IDormer) => (
                    <RoofParts
                      key={element.name}
                      element={element}
                      handleClick={() =>
                        navigate(Path.AddDormer, {
                          dormerId: element?.name,
                          roofId: element?.roofName || "",
                        })
                      }
                      handleDuplicate={() => handleDuplicate(element.name, "dormer")}
                    />
                  ))}
              </DividerStack>
            </Card>
          )}
          <SectionTitle title={t("atticFloor")} sx={{ mt: 5 }}>
            {checkIfAtticFloorObjectIsEmpty && (
              <MainButton
                text={t("add")}
                variant="secondary"
                icon={<PlusIcon color="white" width="10px"></PlusIcon>}
                onClick={() => navigate(Path.AddAtticFloor)}
              />
            )}
          </SectionTitle>
          {checkIfAtticFloorObjectIsEmpty ? (
            <EmptyContainer title={t("noAtticFloorAdded")} />
          ) : (
            <Card sx={{ px: 0 }}>
              <DividerStack divider={<Divider />} spacing={2}>
                {atticFloor && (
                  <CardSection
                    title={t("atticFloor")}
                    errors={atticFloor.errors || []}
                    area={atticFloor.area}
                    icon={HomeIcon}
                    path={"/"}
                    handleClick={() => navigate(Path.AddAtticFloor)}
                  />
                )}
              </DividerStack>
            </Card>
          )}
          <Form onSubmit={handleSubmit(handleSave, handleSave)}>
            <AdditionalDataWrapper title={t("ownerAgreements")}>
              <AdditionalData
                name="ownerRoof"
                form={form}
                suggestions={roofSuggestionsOwner}
                error={errors.ownerRoof}
              />
            </AdditionalDataWrapper>
            <AdditionalDataWrapper title={t("contractorAgreements")}>
              <AdditionalData
                name="supplierRoof"
                form={form}
                suggestions={roofSuggestionExecutive}
                error={errors.supplierRoof}
              />
            </AdditionalDataWrapper>
            <MainButton
              text={t("save")}
              variant="primary"
              type="submit"
              sx={{ margin: "30px 0" }}
            />
          </Form>
        </Stack>
      </PageWrapper>
    </MainPageWrapper>
  );
};
